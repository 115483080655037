const CountriesList = [
  {
    name: "Afghanistan",
    name_ar: "أفغانستان",
    dial_code: "+93",
    currency: "AFN",
    iso2: "AF",
    iso3: "AFG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇫",
    order: 0,
  },
  {
    name: "Albania",
    name_ar: "ألبانيا",
    dial_code: "+355",
    currency: "ALL",
    iso2: "AL",
    iso3: "ALB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇱",
    order: 0,
  },
  {
    name: "Algeria",
    name_ar: "الجزائر",
    dial_code: "+213",
    currency: "DZD",
    iso2: "DZ",
    iso3: "DZA",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇩🇿",
    order: 13,
  },
  {
    name: "Andorra",
    name_ar: "أندورا",
    dial_code: "+376",
    currency: "EUR",
    iso2: "AD",
    iso3: "AND",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇩",
    order: 0,
  },
  {
    name: "Angola",
    name_ar: "أنغولا",
    dial_code: "+244",
    currency: "AOA",
    iso2: "AO",
    iso3: "AGO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇴",
    order: 0,
  },
  {
    name: "Anguilla",
    name_ar: "أنغويلا",
    dial_code: "+1264",
    currency: "XCD",
    iso2: "AI",
    iso3: "AIA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇮",
    order: 0,
  },
  {
    name: "Antigua and Barbuda",
    name_ar: "أنتيغوا وبربودا",
    dial_code: "+1268",
    currency: "XCD",
    iso2: "AG",
    iso3: "ATG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇬",
    order: 0,
  },
  {
    name: "Argentina",
    name_ar: "الأرجنتين",
    dial_code: "+54",
    currency: "ARS",
    iso2: "AR",
    iso3: "ARG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇷",
    order: 0,
  },
  {
    name: "Armenia",
    name_ar: "أرمينيا",
    dial_code: "+374",
    currency: "AMD",
    iso2: "AM",
    iso3: "ARM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇲",
    order: 0,
  },
  {
    name: "Aruba",
    name_ar: "أروبا",
    dial_code: "+297",
    currency: "AWG",
    iso2: "AW",
    iso3: "ABW",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇼",
    order: 0,
  },
  {
    name: "Australia",
    name_ar: "أستراليا",
    dial_code: "+61",
    currency: "AUD",
    iso2: "AU",
    iso3: "AUS",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇺",
    order: 0,
  },
  {
    name: "Austria",
    name_ar: "النمسا",
    dial_code: "+43",
    currency: "EUR",
    iso2: "AT",
    iso3: "AUT",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇹",
    order: 0,
  },
  {
    name: "Azerbaijan",
    name_ar: "أذربيجان",
    dial_code: "+994",
    currency: "AZN",
    iso2: "AZ",
    iso3: "AZE",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇦🇿",
    order: 0,
  },
  {
    name: "Bahamas",
    name_ar: "جزر البهاما",
    dial_code: "+1242",
    currency: "BSD",
    iso2: "BS",
    iso3: "BHS",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇸",
    order: 0,
  },
  {
    name: "Bahrain",
    name_ar: "البحرين",
    dial_code: "+973",
    currency: "BHD",
    iso2: "BH",
    iso3: "BHR",
    is_arabian_country: true,
    is_khaliji_country: true,
    flag: "🇧🇭",
    order: 20,
  },
  {
    name: "Bangladesh",
    name_ar: "بنجلاديش",
    dial_code: "+880",
    currency: "BDT",
    iso2: "BD",
    iso3: "BGD",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇩",
    order: 0,
  },
  {
    name: "Barbados",
    name_ar: "باربادوس",
    dial_code: "+1246",
    currency: "BBD",
    iso2: "BB",
    iso3: "BRB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇧",
    order: 0,
  },
  {
    name: "Belarus",
    name_ar: "بيلاروس",
    dial_code: "+375",
    currency: "BYR",
    iso2: "BY",
    iso3: "BLR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇾",
    order: 0,
  },
  {
    name: "Belgium",
    name_ar: "بلجيكا",
    dial_code: "+32",
    currency: "EUR",
    iso2: "BE",
    iso3: "BEL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇪",
    order: 0,
  },
  {
    name: "Belize",
    name_ar: "بيليز",
    dial_code: "+501",
    currency: "BZD",
    iso2: "BZ",
    iso3: "BLZ",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇿",
    order: 0,
  },
  {
    name: "Benin",
    name_ar: "بنين",
    dial_code: "+229",
    currency: "XOF",
    iso2: "BJ",
    iso3: "BEN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇯",
    order: 0,
  },
  {
    name: "Bermuda",
    name_ar: "برمودا",
    dial_code: "+1441",
    currency: "BMD",
    iso2: "BM",
    iso3: "BMU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇲",
    order: 0,
  },
  {
    name: "Bhutan",
    name_ar: "بوتان",
    dial_code: "+975",
    currency: "BTN",
    iso2: "BT",
    iso3: "BTN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇹",
    order: 0,
  },
  {
    name: "Bolivia",
    name_ar: "بوليفيا",
    dial_code: "+591",
    currency: "BOB",
    iso2: "BO",
    iso3: "BOL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇴",
    order: 0,
  },
  {
    name: "Bosnia and Herzegovina",
    name_ar: "البوسنة والهرسك",
    dial_code: "+387",
    currency: "BAM",
    iso2: "BA",
    iso3: "BIH",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇦",
    order: 0,
  },
  {
    name: "Botswana",
    name_ar: "بوتسوانا",
    dial_code: "+267",
    currency: "BWP",
    iso2: "BW",
    iso3: "BWA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇼",
    order: 0,
  },
  {
    name: "Brazil",
    name_ar: "البرازيل",
    dial_code: "+55",
    currency: "BRL",
    iso2: "BR",
    iso3: "BRA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇷",
    order: 0,
  },
  {
    name: "British Indian Ocean Territory",
    name_ar: "إقليم المحيط الهندي البريطاني",
    dial_code: "+246",
    currency: "USD",
    iso2: "IO",
    iso3: "IOT",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇴",
    order: 0,
  },
  {
    name: "Brunei",
    name_ar: "بروناي",
    dial_code: "+673",
    currency: "BND",
    iso2: "BN",
    iso3: "BRN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇳",
    order: 0,
  },
  {
    name: "Bulgaria",
    name_ar: "بلغاريا",
    dial_code: "+359",
    currency: "BGN",
    iso2: "BG",
    iso3: "BGR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇬",
    order: 0,
  },
  {
    name: "Burkina Faso",
    name_ar: "بوركينا فاسو",
    dial_code: "+226",
    currency: "XOF",
    iso2: "BF",
    iso3: "BFA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇫",
    order: 0,
  },
  {
    name: "Myanmar",
    name_ar: "ميانمار",
    dial_code: "+95",
    currency: "MMK",
    iso2: "MM",
    iso3: "MMR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇲",
    order: 0,
  },
  {
    name: "Burundi",
    name_ar: "بوروندي",
    dial_code: "+257",
    currency: "BIF",
    iso2: "BI",
    iso3: "BDI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇧🇮",
    order: 0,
  },
  {
    name: "Cambodia",
    name_ar: "كامبوديا",
    dial_code: "+855",
    currency: "KHR",
    iso2: "KH",
    iso3: "KHM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇭",
    order: 0,
  },
  {
    name: "Cameroon",
    name_ar: "الكاميرون",
    dial_code: "+237",
    currency: "XAF",
    iso2: "CM",
    iso3: "CMR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇲",
    order: 0,
  },
  {
    name: "Canada",
    name_ar: "كندا",
    dial_code: "+1",
    currency: "CAD",
    iso2: "CA",
    iso3: "CAN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇦",
    order: 0,
  },
  {
    name: "Cape Verde",
    name_ar: "الرأس الأخضر",
    dial_code: "+238",
    currency: "CVE",
    iso2: "CV",
    iso3: "CPV",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇻",
    order: 0,
  },
  {
    name: "Cayman Islands",
    name_ar: "جزر كايمان",
    dial_code: "+1345",
    currency: "KYD",
    iso2: "KY",
    iso3: "CYM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇾",
    order: 0,
  },
  {
    name: "Central African Republic",
    name_ar: "جمهورية أفريقيا الوسطى",
    dial_code: "+236",
    currency: "XAF",
    iso2: "CF",
    iso3: "CAF",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇫",
    order: 0,
  },
  {
    name: "Chad",
    name_ar: "تشاد",
    dial_code: "+235",
    currency: "XAF",
    iso2: "TD",
    iso3: "TCD",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇩",
    order: 0,
  },
  {
    name: "Christmas Island",
    name_ar: "جزيرة الكريسماس",
    dial_code: "+61",
    currency: "AUD",
    iso2: "CX",
    iso3: "CXR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇽",
    order: 0,
  },
  {
    name: "Cocos (Keeling) Islands",
    name_ar: "جزر الكوكوس (كيلين)",
    dial_code: "+61",
    currency: "AUD",
    iso2: "CC",
    iso3: "CCK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇨",
    order: 0,
  },
  {
    name: "Cook Islands",
    name_ar: "جزر كوك",
    dial_code: "+682",
    currency: "NZD",
    iso2: "CK",
    iso3: "COK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇰",
    order: 0,
  },
  {
    name: "Chile",
    name_ar: "تشيلي",
    dial_code: "+56",
    currency: "CLP",
    iso2: "CL",
    iso3: "CHL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇱",
    order: 0,
  },
  {
    name: "China",
    name_ar: "الصين",
    dial_code: "+86",
    currency: "CNY",
    iso2: "CN",
    iso3: "CHN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇳",
    order: 0,
  },
  {
    name: "Colombia",
    name_ar: "كولومبيا",
    dial_code: "+57",
    currency: "COP",
    iso2: "CO",
    iso3: "COL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇴",
    order: 0,
  },
  {
    name: "Comoros",
    name_ar: "جزر القمر",
    dial_code: "+269",
    currency: "KMF",
    iso2: "KM",
    iso3: "COM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇲",
    order: 0,
  },
  {
    name: "Congo",
    name_ar: "الكونغو",
    dial_code: "+242",
    currency: "XAF",
    iso2: "CG",
    iso3: "COG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇬",
    order: 0,
  },
  {
    name: "Costa Rica",
    name_ar: "كوستاريكا",
    dial_code: "+506",
    currency: "CRC",
    iso2: "CR",
    iso3: "CRI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇷",
    order: 0,
  },
  {
    name: "Croatia",
    name_ar: "كرواتيا",
    dial_code: "+385",
    currency: "HRK",
    iso2: "HR",
    iso3: "HRV",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇭🇷",
    order: 0,
  },
  {
    name: "Cuba",
    name_ar: "كوبا",
    dial_code: "+53",
    currency: "CUP",
    iso2: "CU",
    iso3: "CUB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇺",
    order: 0,
  },
  {
    name: "Cyprus",
    name_ar: "قبرص",
    dial_code: "+357",
    currency: "EUR",
    iso2: "CY",
    iso3: "CYP",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇾",
    order: 0,
  },
  {
    name: "Czech Republic",
    name_ar: "جمهورية التشيك",
    dial_code: "+420",
    currency: "CZK",
    iso2: "CZ",
    iso3: "CZE",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇿",
    order: 0,
  },
  {
    name: "Denmark",
    name_ar: "الدانمارك",
    dial_code: "+45",
    currency: "DKK",
    iso2: "DK",
    iso3: "DNK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇩🇰",
    order: 0,
  },
  {
    name: "Djibouti",
    name_ar: "جيبوتي",
    dial_code: "+253",
    currency: "DJF",
    iso2: "DJ",
    iso3: "DJI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇩🇯",
    order: 0,
  },
  {
    name: "Dominica",
    name_ar: "دومينيكا",
    dial_code: "+1767",
    currency: "XCD",
    iso2: "DM",
    iso3: "DMA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇩🇲",
    order: 0,
  },
  {
    name: "Dominican Republic",
    name_ar: "جمهورية الدومينيكان",
    dial_code: "+1849",
    currency: "DOP",
    iso2: "DO",
    iso3: "DOM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇩🇴",
    order: 0,
  },
  {
    name: "Timor-Leste",
    name_ar: "تيمور الشرقية",
    dial_code: "+670",
    currency: "USD",
    iso2: "TL",
    iso3: "TLS",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇱",
    order: 0,
  },
  {
    name: "Tokelau",
    name_ar: "توكيلاو",
    dial_code: "+690",
    currency: "NZD",
    iso2: "TK",
    iso3: "TKL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇰",
    order: 0,
  },
  {
    name: "Turks and Caicos Islands",
    name_ar: "جزر تركس وكايكوس",
    dial_code: "+1649",
    currency: "USD",
    iso2: "TC",
    iso3: "TCA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇨",
    order: 0,
  },
  {
    name: "Ecuador",
    name_ar: "إكوادور",
    dial_code: "+593",
    currency: "USD",
    iso2: "EC",
    iso3: "ECU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇪🇨",
    order: 0,
  },
  {
    name: "Egypt",
    name_ar: "مصر",
    dial_code: "+20",
    currency: "EGP",
    iso2: "EG",
    iso3: "EGY",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇪🇬",
    order: 16,
  },
  {
    name: "El Salvador",
    name_ar: "السلفادور",
    dial_code: "+503",
    currency: "USD",
    iso2: "SV",
    iso3: "SLV",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇻",
    order: 0,
  },
  {
    name: "Equatorial Guinea",
    name_ar: "غينيا الاستوائية",
    dial_code: "+240",
    currency: "XAF",
    iso2: "GQ",
    iso3: "GNQ",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇶",
    order: 0,
  },
  {
    name: "Eritrea",
    name_ar: "إريتريا",
    dial_code: "+291",
    currency: "ERN",
    iso2: "ER",
    iso3: "ERI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇪🇷",
    order: 0,
  },
  {
    name: "Estonia",
    name_ar: "إستونيا",
    dial_code: "+372",
    currency: "EUR",
    iso2: "EE",
    iso3: "EST",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇪🇪",
    order: 0,
  },
  {
    name: "Ethiopia",
    name_ar: "إثيوبيا",
    dial_code: "+251",
    currency: "ETB",
    iso2: "ET",
    iso3: "ETH",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇪🇹",
    order: 0,
  },
  {
    name: "Faroe Islands",
    name_ar: "جزر فايرو",
    dial_code: "+298",
    currency: "DKK",
    iso2: "FO",
    iso3: "FRO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇫🇴",
    order: 0,
  },
  {
    name: "Falkland Islands",
    name_ar: "جزر فوكلاند",
    dial_code: "+500",
    currency: "FKP",
    iso2: "FK",
    iso3: "FLK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇫🇰",
    order: 0,
  },
  {
    name: "Fiji",
    name_ar: "جزر فيجي",
    dial_code: "+679",
    currency: "FJD",
    iso2: "FJ",
    iso3: "FJI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇫🇯",
    order: 0,
  },
  {
    name: "Finland",
    name_ar: "فنلندا",
    dial_code: "+358",
    currency: "EUR",
    iso2: "FI",
    iso3: "FIN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇫🇮",
    order: 0,
  },
  {
    name: "France",
    name_ar: "فرنسا",
    dial_code: "+33",
    currency: "EUR",
    iso2: "FR",
    iso3: "FRA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇫🇷",
    order: 0,
  },
  {
    name: "French Polynesia",
    name_ar: "بولينزيا الفرنسية",
    dial_code: "+689",
    currency: "XPF",
    iso2: "PF",
    iso3: "PYF",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇫",
    order: 0,
  },
  {
    name: "Gabon",
    name_ar: "الجابون",
    dial_code: "+241",
    currency: "XAF",
    iso2: "GA",
    iso3: "GAB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇦",
    order: 0,
  },
  {
    name: "Gambia",
    name_ar: "جامبيا",
    dial_code: "+220",
    currency: "GMD",
    iso2: "GM",
    iso3: "GMB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇲",
    order: 0,
  },
  {
    name: "Georgia",
    name_ar: "جورجيا",
    dial_code: "+995",
    currency: "GEL",
    iso2: "GE",
    iso3: "GEO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇪",
    order: 0,
  },
  {
    name: "Gibraltar",
    name_ar: "جبل طارق",
    dial_code: "+350",
    currency: "GIP",
    iso2: "GI",
    iso3: "GIB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇮",
    order: 0,
  },
  {
    name: "Germany",
    name_ar: "ألمانيا",
    dial_code: "+49",
    currency: "EUR",
    iso2: "DE",
    iso3: "DEU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇩🇪",
    order: 0,
  },
  {
    name: "Ghana",
    name_ar: "غانا",
    dial_code: "+233",
    currency: "GHS",
    iso2: "GH",
    iso3: "GHA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇭",
    order: 0,
  },
  {
    name: "Greece",
    name_ar: "اليونان",
    dial_code: "+30",
    currency: "EUR",
    iso2: "GR",
    iso3: "GRC",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇷",
    order: 0,
  },
  {
    name: "Greenland",
    name_ar: "جرينلاند",
    dial_code: "+299",
    currency: "DKK",
    iso2: "GL",
    iso3: "GRL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇱",
    order: 0,
  },
  {
    name: "Guadeloupe",
    name_ar: "جواديلوب",
    dial_code: "+590",
    currency: "EUR",
    iso2: "GP",
    iso3: "GLP",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇵",
    order: 0,
  },
  {
    name: "Grenada",
    name_ar: "غرينادا",
    dial_code: "+1473",
    currency: "XCD",
    iso2: "GD",
    iso3: "GRD",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇩",
    order: 0,
  },
  {
    name: "Guernsey",
    name_ar: "جيرنزي",
    dial_code: "+44",
    currency: "GBP",
    iso2: "GG",
    iso3: "GGY",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇬",
    order: 0,
  },
  {
    name: "Guatemala",
    name_ar: "جواتيمالا",
    dial_code: "+502",
    currency: "GTQ",
    iso2: "GT",
    iso3: "GTM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇹",
    order: 0,
  },
  {
    name: "Guam",
    name_ar: "جوام",
    dial_code: "+1671",
    currency: "USD",
    iso2: "GU",
    iso3: "GUM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇺",
    order: 0,
  },
  {
    name: "Guinea",
    name_ar: "غينيا",
    dial_code: "+224",
    currency: "GNF",
    iso2: "GN",
    iso3: "GIN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇳",
    order: 0,
  },
  {
    name: "Guinea-Bissau",
    name_ar: "غينيا بيساو",
    dial_code: "+245",
    currency: "XOF",
    iso2: "GW",
    iso3: "GNB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇼",
    order: 0,
  },
  {
    name: "Guyana",
    name_ar: "جيانا",
    dial_code: "+592",
    currency: "GYD",
    iso2: "GY",
    iso3: "GUY",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇾",
    order: 0,
  },
  {
    name: "Haiti",
    name_ar: "هايتي",
    dial_code: "+509",
    currency: "HTG",
    iso2: "HT",
    iso3: "HTI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇭🇹",
    order: 0,
  },
  {
    name: "Heard Island and McDonald Islands",
    name_ar: "جزيرة هيرد وجزر ماكدونالد",
    dial_code: "+ ",
    currency: "AUD",
    iso2: "HM",
    iso3: "HMD",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇭🇲",
    order: 0,
  },
  {
    name: "Jordan",
    name_ar: "الأردن",
    dial_code: "+962",
    currency: "JOD",
    iso2: "JO",
    iso3: "JOR",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇯🇴",
    order: 4,
  },
  {
    name: "Yemen",
    name_ar: "اليمن",
    dial_code: "+967",
    currency: "YER",
    iso2: "YE",
    iso3: "YEM",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇾🇪",
    order: 3,
  },
  {
    name: "Honduras",
    name_ar: "هندوراس",
    dial_code: "+504",
    currency: "HNL",
    iso2: "HN",
    iso3: "HND",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇭🇳",
    order: 0,
  },
  {
    name: "Hong Kong",
    name_ar: "هونغ كونغ SAR",
    dial_code: "+852",
    currency: "HKD",
    iso2: "HK",
    iso3: "HKG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇭🇰",
    order: 0,
  },
  {
    name: "Hungary",
    name_ar: "المجر",
    dial_code: "+36",
    currency: "HUF",
    iso2: "HU",
    iso3: "HUN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇭🇺",
    order: 0,
  },
  {
    name: "Iceland",
    name_ar: "أيسلندا",
    dial_code: "+354",
    currency: "ISK",
    iso2: "IS",
    iso3: "ISL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇸",
    order: 0,
  },
  {
    name: "India",
    name_ar: "الهند",
    dial_code: "+91",
    currency: "INR",
    iso2: "IN",
    iso3: "IND",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇳",
    order: 0,
  },
  {
    name: "Indonesia",
    name_ar: "إندونيسيا",
    dial_code: "+62",
    currency: "IDR",
    iso2: "ID",
    iso3: "IDN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇩",
    order: 0,
  },
  {
    name: "Iran",
    name_ar: "إيران",
    dial_code: "+98",
    currency: "IRR",
    iso2: "IR",
    iso3: "IRN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇷",
    order: 0,
  },
  {
    name: "Iraq",
    name_ar: "العراق",
    dial_code: "+964",
    currency: "IQD",
    iso2: "IQ",
    iso3: "IRQ",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇮🇶",
    order: 6,
  },
  {
    name: "Ireland",
    name_ar: "أيرلندا",
    dial_code: "+353",
    currency: "EUR",
    iso2: "IE",
    iso3: "IRL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇪",
    order: 0,
  },
  {
    name: "Isle of Man",
    name_ar: "جزيرة مان",
    dial_code: "+44",
    currency: "GBP",
    iso2: "IM",
    iso3: "IMN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇲",
    order: 0,
  },
  {
    name: "Palestine",
    name_ar: "فلسطين",
    dial_code: "+970",
    currency: "ILS",
    iso2: "PS",
    iso3: "PSE",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇵🇸",
    order: 7,
  },
  {
    name: "Israel",
    name_ar: "إسرائيل",
    dial_code: "+972",
    currency: "ILS",
    iso2: "IL",
    iso3: "ISR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇱",
    order: 0,
  },
  {
    name: "Italy",
    name_ar: "إيطاليا",
    dial_code: "+39",
    currency: "EUR",
    iso2: "IT",
    iso3: "ITA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇮🇹",
    order: 0,
  },
  {
    name: "Ivory Coast",
    name_ar: "كوت ديفوار",
    dial_code: "+225",
    currency: "XOF",
    iso2: "CI",
    iso3: "CIV",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇮",
    order: 0,
  },
  {
    name: "Jamaica",
    name_ar: "جامايكا",
    dial_code: "+1876",
    currency: "JMD",
    iso2: "JM",
    iso3: "JAM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇯🇲",
    order: 0,
  },
  {
    name: "Jersey",
    name_ar: "جيرسي",
    dial_code: "+44",
    currency: "GBP",
    iso2: "JE",
    iso3: "JEY",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇯🇪",
    order: 0,
  },
  {
    name: "Japan",
    name_ar: "اليابان",
    dial_code: "+81",
    currency: "JPY",
    iso2: "JP",
    iso3: "JPN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇯🇵",
    order: 0,
  },
  {
    name: "Kazakhstan",
    name_ar: "كازاخستان",
    dial_code: "+7",
    currency: "KZT",
    iso2: "KZ",
    iso3: "KAZ",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇿",
    order: 0,
  },
  {
    name: "Kenya",
    name_ar: "كينيا",
    dial_code: "+254",
    currency: "KES",
    iso2: "KE",
    iso3: "KEN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇪",
    order: 0,
  },
  {
    name: "Kiribati",
    name_ar: "كيريباتي",
    dial_code: "+686",
    currency: "AUD",
    iso2: "KI",
    iso3: "KIR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇮",
    order: 0,
  },
  {
    name: "North Korea",
    name_ar: "كوريا الشمالية",
    dial_code: "+850",
    currency: "KPW",
    iso2: "KP",
    iso3: "PRK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇵",
    order: 0,
  },
  {
    name: "Kosovo",
    name_ar: "كوسوفو",
    dial_code: "+383",
    currency: "EUR",
    iso2: "XK",
    iso3: "XKX",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇽🇰",
    order: 0,
  },
  {
    name: "Kuwait",
    name_ar: "الكويت",
    dial_code: "+965",
    currency: "KWD",
    iso2: "KW",
    iso3: "KWT",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇰🇼",
    order: 21,
  },
  {
    name: "Kyrgyzstan",
    name_ar: "قيرقيزستان",
    dial_code: "+996",
    currency: "KGS",
    iso2: "KG",
    iso3: "KGZ",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇬",
    order: 0,
  },
  {
    name: "Laos",
    name_ar: "لاوس",
    dial_code: "+856",
    currency: "LAK",
    iso2: "LA",
    iso3: "LAO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇦",
    order: 0,
  },
  {
    name: "Latvia",
    name_ar: "لاتفيا",
    dial_code: "+371",
    currency: "EUR",
    iso2: "LV",
    iso3: "LVA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇻",
    order: 0,
  },
  {
    name: "Lebanon",
    name_ar: "لبنان",
    dial_code: "+961",
    currency: "LBP",
    iso2: "LB",
    iso3: "LBN",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇱🇧",
    order: 5,
  },
  {
    name: "Lesotho",
    name_ar: "ليسوتو",
    dial_code: "+266",
    currency: "LSL",
    iso2: "LS",
    iso3: "LSO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇸",
    order: 0,
  },
  {
    name: "Liberia",
    name_ar: "ليبيريا",
    dial_code: "+231",
    currency: "LRD",
    iso2: "LR",
    iso3: "LBR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇷",
    order: 0,
  },
  {
    name: "Libya",
    name_ar: "ليبيا",
    dial_code: "+218",
    currency: "LYD",
    iso2: "LY",
    iso3: "LBY",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇱🇾",
    order: 11,
  },
  {
    name: "Liechtenstein",
    name_ar: "لختنشتاين",
    dial_code: "+423",
    currency: "CHF",
    iso2: "LI",
    iso3: "LIE",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇮",
    order: 0,
  },
  {
    name: "Luxembourg",
    name_ar: "لوكسمبورج",
    dial_code: "+352",
    currency: "EUR",
    iso2: "LU",
    iso3: "LUX",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇺",
    order: 0,
  },
  {
    name: "Macedonia",
    name_ar: "مقدونيا",
    dial_code: "+389",
    currency: "MKD",
    iso2: "MK",
    iso3: "MKD",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇰",
    order: 0,
  },
  {
    name: "Madagascar",
    name_ar: "مدغشقر",
    dial_code: "+261",
    currency: "MGA",
    iso2: "MG",
    iso3: "MDG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇬",
    order: 0,
  },
  {
    name: "Marshall Islands",
    name_ar: "جزر مارشال",
    dial_code: "+692",
    currency: "USD",
    iso2: "MH",
    iso3: "MHL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇭",
    order: 0,
  },
  {
    name: "Macau",
    name_ar: "ماكاو",
    dial_code: "+853",
    currency: "MOP",
    iso2: "MO",
    iso3: "MAC",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇴",
    order: 0,
  },
  {
    name: "Malawi",
    name_ar: "مالاوي",
    dial_code: "+265",
    currency: "MWK",
    iso2: "MW",
    iso3: "MWI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇼",
    order: 0,
  },
  {
    name: "Malaysia",
    name_ar: "ماليزيا",
    dial_code: "+60",
    currency: "MYR",
    iso2: "MY",
    iso3: "MYS",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇾",
    order: 0,
  },
  {
    name: "Maldives",
    name_ar: "جزر المالديف",
    dial_code: "+960",
    currency: "MVR",
    iso2: "MV",
    iso3: "MDV",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇻",
    order: 0,
  },
  {
    name: "Mali",
    name_ar: "مالي",
    dial_code: "+223",
    currency: "XOF",
    iso2: "ML",
    iso3: "MLI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇱",
    order: 0,
  },
  {
    name: "Malta",
    name_ar: "مالطا",
    dial_code: "+356",
    currency: "EUR",
    iso2: "MT",
    iso3: "MLT",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇹",
    order: 0,
  },
  {
    name: "Martinique",
    name_ar: "مارتينيك",
    dial_code: "+596",
    currency: "EUR",
    iso2: "MQ",
    iso3: "MTQ",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇶",
    order: 0,
  },
  {
    name: "Mauritania",
    name_ar: "موريتانيا",
    dial_code: "+222",
    currency: "MRO",
    iso2: "MR",
    iso3: "MRT",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇲🇷",
    order: 15,
  },
  {
    name: "Mauritius",
    name_ar: "موريشيوس",
    dial_code: "+230",
    currency: "MUR",
    iso2: "MU",
    iso3: "MUS",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇺",
    order: 0,
  },
  {
    name: "Mayotte",
    name_ar: "مايوت",
    dial_code: "+262",
    currency: "EUR",
    iso2: "YT",
    iso3: "MYT",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇾🇹",
    order: 0,
  },
  {
    name: "Mexico",
    name_ar: "المكسيك",
    dial_code: "+52",
    currency: "MXN",
    iso2: "MX",
    iso3: "MEX",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇽",
    order: 0,
  },
  {
    name: "Moldova",
    name_ar: "مولدوفا",
    dial_code: "+373",
    currency: "MDL",
    iso2: "MD",
    iso3: "MDA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇩",
    order: 0,
  },
  {
    name: "Mongolia",
    name_ar: "منغوليا",
    dial_code: "+976",
    currency: "MNT",
    iso2: "MN",
    iso3: "MNG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇳",
    order: 0,
  },
  {
    name: "Montenegro",
    name_ar: "مونتينيغرو",
    dial_code: "+382",
    currency: "EUR",
    iso2: "ME",
    iso3: "MNE",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇪",
    order: 0,
  },
  {
    name: "Monaco",
    name_ar: "موناكو",
    dial_code: "+377",
    currency: "EUR",
    iso2: "MC",
    iso3: "MCO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇨",
    order: 0,
  },
  {
    name: "Montserrat",
    name_ar: "مونتسيرات",
    dial_code: "+1664",
    currency: "XCD",
    iso2: "MS",
    iso3: "MSR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇸",
    order: 0,
  },
  {
    name: "Morocco",
    name_ar: "المغرب",
    dial_code: "+212",
    currency: "MAD",
    iso2: "MA",
    iso3: "MAR",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇲🇦",
    order: 14,
  },
  {
    name: "Mozambique",
    name_ar: "موزمبيق",
    dial_code: "+258",
    currency: "MZN",
    iso2: "MZ",
    iso3: "MOZ",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇿",
    order: 0,
  },
  {
    name: "Namibia",
    name_ar: "ناميبيا",
    dial_code: "+264",
    currency: "NAD",
    iso2: "NA",
    iso3: "NAM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇦",
    order: 0,
  },
  {
    name: "Nauru",
    name_ar: "ناورو",
    dial_code: "+674",
    currency: "AUD",
    iso2: "NR",
    iso3: "NRU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇷",
    order: 0,
  },
  {
    name: "Nepal",
    name_ar: "نيبال",
    dial_code: "+977",
    currency: "NPR",
    iso2: "NP",
    iso3: "NPL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇵",
    order: 0,
  },
  {
    name: "Netherlands",
    name_ar: "هولندا",
    dial_code: "+31",
    currency: "EUR",
    iso2: "NL",
    iso3: "NLD",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇱",
    order: 0,
  },
  {
    name: "New Caledonia",
    name_ar: "كاليدونيا الجديدة",
    dial_code: "+687",
    currency: "XPF",
    iso2: "NC",
    iso3: "NCL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇨",
    order: 0,
  },
  {
    name: "New Zealand",
    name_ar: "نيوزيلندا",
    dial_code: "+64",
    currency: "NZD",
    iso2: "NZ",
    iso3: "NZL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇿",
    order: 0,
  },
  {
    name: "Nicaragua",
    name_ar: "نيكاراجوا",
    dial_code: "+505",
    currency: "NIO",
    iso2: "NI",
    iso3: "NIC",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇮",
    order: 0,
  },
  {
    name: "Niger",
    name_ar: "النيجر",
    dial_code: "+227",
    currency: "XOF",
    iso2: "NE",
    iso3: "NER",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇪",
    order: 0,
  },
  {
    name: "Nigeria",
    name_ar: "نيجيريا",
    dial_code: "+234",
    currency: "NGN",
    iso2: "NG",
    iso3: "NGA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇬",
    order: 0,
  },
  {
    name: "Norway",
    name_ar: "النرويج",
    dial_code: "+47",
    currency: "NOK",
    iso2: "NO",
    iso3: "NOR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇴",
    order: 0,
  },
  {
    name: "Niue",
    name_ar: "نيوا",
    dial_code: "+683",
    currency: "NZD",
    iso2: "NU",
    iso3: "NIU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇺",
    order: 0,
  },
  {
    name: "Norfolk Island",
    name_ar: "جزيرة نورفوك",
    dial_code: "+672",
    currency: "AUD",
    iso2: "NF",
    iso3: "NFK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇳🇫",
    order: 0,
  },
  {
    name: "Northern Mariana Islands",
    name_ar: "جزر ماريانا الشمالية",
    dial_code: "+1670",
    currency: "USD",
    iso2: "MP",
    iso3: "MNP",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇲🇵",
    order: 0,
  },
  {
    name: "Oman",
    name_ar: "عمان",
    dial_code: "+968",
    currency: "OMR",
    iso2: "OM",
    iso3: "OMN",
    is_arabian_country: true,
    is_khaliji_country: true,
    flag: "🇴🇲",
    order: 17,
  },
  {
    name: "Pakistan",
    name_ar: "باكستان",
    dial_code: "+92",
    currency: "PKR",
    iso2: "PK",
    iso3: "PAK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇰",
    order: 0,
  },
  {
    name: "Pitcairn",
    name_ar: "جزر البتكارين",
    dial_code: "+870",
    currency: "NZD",
    iso2: "PN",
    iso3: "PCN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇳",
    order: 0,
  },
  {
    name: "Palau",
    name_ar: "بالاو",
    dial_code: "+680",
    currency: "USD",
    iso2: "PW",
    iso3: "PLW",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇼",
    order: 0,
  },
  {
    name: "Panama",
    name_ar: "بنما",
    dial_code: "+507",
    currency: "PAB",
    iso2: "PA",
    iso3: "PAN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇦",
    order: 0,
  },
  {
    name: "Papua New Guinea",
    name_ar: "بابوا-غينيا الجديدة",
    dial_code: "+675",
    currency: "PGK",
    iso2: "PG",
    iso3: "PNG",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇬",
    order: 0,
  },
  {
    name: "Paraguay",
    name_ar: "باراجواي",
    dial_code: "+595",
    currency: "PYG",
    iso2: "PY",
    iso3: "PRY",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇾",
    order: 0,
  },
  {
    name: "Peru",
    name_ar: "بيرو",
    dial_code: "+51",
    currency: "PEN",
    iso2: "PE",
    iso3: "PER",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇪",
    order: 0,
  },
  {
    name: "Philippines",
    name_ar: "الفلبين",
    dial_code: "+63",
    currency: "PHP",
    iso2: "PH",
    iso3: "PHL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇭",
    order: 0,
  },
  {
    name: "Poland",
    name_ar: "بولندا",
    dial_code: "+48",
    currency: "PLN",
    iso2: "PL",
    iso3: "POL",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇱",
    order: 0,
  },
  {
    name: "Portugal",
    name_ar: "البرتغال",
    dial_code: "+351",
    currency: "EUR",
    iso2: "PT",
    iso3: "PRT",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇹",
    order: 0,
  },
  {
    name: "Puerto Rico",
    name_ar: "بورتو ريكو",
    dial_code: "+1939",
    currency: "USD",
    iso2: "PR",
    iso3: "PRI",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇷",
    order: 0,
  },
  {
    name: "South Korea",
    name_ar: "كوريا",
    dial_code: "+82",
    currency: "KRW",
    iso2: "KR",
    iso3: "KOR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇷",
    order: 0,
  },
  {
    name: "Lithuania",
    name_ar: "ليتوانيا",
    dial_code: "+370",
    currency: "LTL",
    iso2: "LT",
    iso3: "LTU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇹",
    order: 0,
  },
  {
    name: "Qatar",
    name_ar: "قطر",
    dial_code: "+974",
    currency: "QAR",
    iso2: "QA",
    iso3: "QAT",
    is_arabian_country: true,
    is_khaliji_country: true,
    flag: "🇶🇦",
    order: 19,
  },
  {
    name: "Romania",
    name_ar: "رومانيا",
    dial_code: "+40",
    currency: "RON",
    iso2: "RO",
    iso3: "ROU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇷🇴",
    order: 0,
  },
  {
    name: "Russia",
    name_ar: "روسيا",
    dial_code: "+7",
    currency: "RUB",
    iso2: "RU",
    iso3: "RUS",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇷🇺",
    order: 0,
  },
  {
    name: "Rwanda",
    name_ar: "رواندا",
    dial_code: "+250",
    currency: "RWF",
    iso2: "RW",
    iso3: "RWA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇷🇼",
    order: 0,
  },
  {
    name: "Réunion",
    name_ar: "ريونيون",
    dial_code: "+262",
    currency: "EUR",
    iso2: "RE",
    iso3: "REU",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇷🇪",
    order: 0,
  },
  {
    name: "Samoa",
    name_ar: "ساموا",
    dial_code: "+685",
    currency: "WST",
    iso2: "WS",
    iso3: "WSM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇼🇸",
    order: 0,
  },
  {
    name: "Saint Lucia",
    name_ar: "سانت لوشيا",
    dial_code: "+1758",
    currency: "XCD",
    iso2: "LC",
    iso3: "LCA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇨",
    order: 0,
  },
  {
    name: "San Marino",
    name_ar: "سان مارينو",
    dial_code: "+378",
    currency: "EUR",
    iso2: "SM",
    iso3: "SMR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇲",
    order: 0,
  },
  {
    name: "Saint Kitts and Nevis",
    name_ar: "سانت كيتس ونيفيس",
    dial_code: "+1869",
    currency: "XCD",
    iso2: "KN",
    iso3: "KNA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇰🇳",
    order: 0,
  },
  {
    name: "Saint Pierre and Miquelon",
    name_ar: "سانت بيير وميكولون",
    dial_code: "+508",
    currency: "EUR",
    iso2: "PM",
    iso3: "SPM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇵🇲",
    order: 0,
  },
  {
    name: "Saint Vincent and the Grenadines",
    name_ar: "سانت فينسنت وجرينادينز",
    dial_code: "+1784",
    currency: "XCD",
    iso2: "VC",
    iso3: "VCT",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇻🇨",
    order: 0,
  },
  {
    name: "Saudi Arabia",
    name_ar: "السعودية",
    dial_code: "+966",
    currency: "SAR",
    iso2: "SA",
    iso3: "SAU",
    is_arabian_country: true,
    is_khaliji_country: true,
    flag: "🇸🇦",
    order: 22,
  },
  {
    name: "Senegal",
    name_ar: "السنغال",
    dial_code: "+221",
    currency: "XOF",
    iso2: "SN",
    iso3: "SEN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇳",
    order: 0,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    name_ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    dial_code: "+500",
    currency: "GBP",
    iso2: "GS",
    iso3: "SGS",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇸",
    order: 0,
  },
  {
    name: "Sao Tome and Principe",
    name_ar: "ساوتوماي وبرينسيبا",
    dial_code: "+239",
    currency: "STD",
    iso2: "ST",
    iso3: "STP",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇹",
    order: 0,
  },
  {
    name: "Sierra Leone",
    name_ar: "سيراليون",
    dial_code: "+232",
    currency: "SLL",
    iso2: "SL",
    iso3: "SLE",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇱",
    order: 0,
  },
  {
    name: "Serbia",
    name_ar: "صربيا",
    dial_code: "+381",
    currency: "RSD",
    iso2: "RS",
    iso3: "SRB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇷🇸",
    order: 0,
  },
  {
    name: "Seychelles",
    name_ar: "سيشل",
    dial_code: "+248",
    currency: "SCR",
    iso2: "SC",
    iso3: "SYC",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇨",
    order: 0,
  },
  {
    name: "Singapore",
    name_ar: "سنغافورة",
    dial_code: "+65",
    currency: "SGD",
    iso2: "SG",
    iso3: "SGP",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇬",
    order: 0,
  },
  {
    name: "Slovakia",
    name_ar: "سلوفاكيا",
    dial_code: "+421",
    currency: "EUR",
    iso2: "SK",
    iso3: "SVK",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇰",
    order: 0,
  },
  {
    name: "Slovenia",
    name_ar: "سلوفينيا",
    dial_code: "+386",
    currency: "EUR",
    iso2: "SI",
    iso3: "SVN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇮",
    order: 0,
  },
  {
    name: "Solomon Islands",
    name_ar: "جزر سولومون",
    dial_code: "+677",
    currency: "SBD",
    iso2: "SB",
    iso3: "SLB",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇧",
    order: 0,
  },
  {
    name: "Somalia",
    name_ar: "الصومال",
    dial_code: "+252",
    currency: "SOS",
    iso2: "SO",
    iso3: "SOM",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇸🇴",
    order: 9,
  },
  {
    name: "South Africa",
    name_ar: "جنوب أفريقيا",
    dial_code: "+27",
    currency: "ZAR",
    iso2: "ZA",
    iso3: "ZAF",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇿🇦",
    order: 0,
  },
  {
    name: "Spain",
    name_ar: "أسبانيا",
    dial_code: "+34",
    currency: "EUR",
    iso2: "ES",
    iso3: "ESP",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇪🇸",
    order: 0,
  },
  {
    name: "Sri Lanka",
    name_ar: "سيريلانكا",
    dial_code: "+94",
    currency: "LKR",
    iso2: "LK",
    iso3: "LKA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇱🇰",
    order: 0,
  },
  {
    name: "Sudan",
    name_ar: "السودان",
    dial_code: "+249",
    currency: "SDG",
    iso2: "SD",
    iso3: "SDN",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇸🇩",
    order: 10,
  },
  {
    name: "Suriname",
    name_ar: "سورينام",
    dial_code: "+597",
    currency: "SRD",
    iso2: "SR",
    iso3: "SUR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇷",
    order: 0,
  },
  {
    name: "Swaziland",
    name_ar: "سوازيلاند",
    dial_code: "+268",
    currency: "SZL",
    iso2: "SZ",
    iso3: "SWZ",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇿",
    order: 0,
  },
  {
    name: "Sweden",
    name_ar: "السويد",
    dial_code: "+46",
    currency: "SEK",
    iso2: "SE",
    iso3: "SWE",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇸🇪",
    order: 0,
  },
  {
    name: "Switzerland",
    name_ar: "سويسرا",
    dial_code: "+41",
    currency: "CHF",
    iso2: "CH",
    iso3: "CHE",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇨🇭",
    order: 0,
  },
  {
    name: "Syria",
    name_ar: "سوريا",
    dial_code: "+963",
    currency: "SYP",
    iso2: "SY",
    iso3: "SYR",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇸🇾",
    order: 8,
  },
  {
    name: "Taiwan",
    name_ar: "تايوان",
    dial_code: "+886",
    currency: "TWD",
    iso2: "TW",
    iso3: "TWN",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇼",
    order: 0,
  },
  {
    name: "Tanzania",
    name_ar: "تنزانيا",
    dial_code: "+255",
    currency: "TZS",
    iso2: "TZ",
    iso3: "TZA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇿",
    order: 0,
  },
  {
    name: "Thailand",
    name_ar: "تايلاند",
    dial_code: "+66",
    currency: "THB",
    iso2: "TH",
    iso3: "THA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇭",
    order: 0,
  },
  {
    name: "Togo",
    name_ar: "توجو",
    dial_code: "+228",
    currency: "XOF",
    iso2: "TG",
    iso3: "TGO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇬",
    order: 0,
  },
  {
    name: "Tonga",
    name_ar: "تونجا",
    dial_code: "+676",
    currency: "TOP",
    iso2: "TO",
    iso3: "TON",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇴",
    order: 0,
  },
  {
    name: "Trinidad and Tobago",
    name_ar: "ترينيداد وتوباجو",
    dial_code: "+1868",
    currency: "TTD",
    iso2: "TT",
    iso3: "TTO",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇹",
    order: 0,
  },
  {
    name: "Tunisia",
    name_ar: "تونس",
    dial_code: "+216",
    currency: "TND",
    iso2: "TN",
    iso3: "TUN",
    is_arabian_country: true,
    is_khaliji_country: false,
    flag: "🇹🇳",
    order: 12,
  },
  {
    name: "Turkey",
    name_ar: "تركيا",
    dial_code: "+90",
    currency: "TRY",
    iso2: "TR",
    iso3: "TUR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇷",
    order: 0,
  },
  {
    name: "Turkmenistan",
    name_ar: "تركمانستان",
    dial_code: "+993",
    currency: "TMT",
    iso2: "TM",
    iso3: "TKM",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇹🇲",
    order: 0,
  },
  {
    name: "Uganda",
    name_ar: "أوغندا",
    dial_code: "+256",
    currency: "UGX",
    iso2: "UG",
    iso3: "UGA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇺🇬",
    order: 0,
  },
  {
    name: "Ukraine",
    name_ar: "أوكرانيا",
    dial_code: "+380",
    currency: "UAH",
    iso2: "UA",
    iso3: "UKR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇺🇦",
    order: 0,
  },
  {
    name: "United Arab Emirates",
    name_ar: "الإمارات",
    dial_code: "+971",
    currency: "AED",
    iso2: "AE",
    iso3: "ARE",
    is_arabian_country: true,
    is_khaliji_country: true,
    flag: "🇦🇪",
    order: 18,
  },
  {
    name: "United Kingdom",
    name_ar: "المملكة المتحدة",
    dial_code: "+44",
    currency: "GBP",
    iso2: "GB",
    iso3: "GBR",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇬🇧",
    order: 0,
  },
  {
    name: "United States",
    name_ar: "الولايات المتحدة",
    dial_code: "+1",
    currency: "USD",
    iso2: "US",
    iso3: "USA",
    is_arabian_country: false,
    is_khaliji_country: false,
    flag: "🇺🇸",
    order: 0,
  },
];

export default CountriesList;

<template>
  <b-modal
    :id="`CustomConfirmClearData-${dialogName}`"
    scrollable
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/alert.svg" class="icon-lg" />
        {{ title }}
      </h3>
    </template>
    <h4 class="text-center">
      {{ msg }}
    </h4>
    <div class="modal-footer">
      <button
        v-for="(action, index) in actions"
        :key="index"
        name="submit"
        type="submit"
        :class="action.btnClass"
        @click.prevent="applyAction(action.fullBack)"
      >
        {{ action.title }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import generalMixin from "./../../utils/generalMixin";
import i18n from "./../../i18n";

export default {
  mixins: [generalMixin],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: () => i18n.t("alert"),
    },
    msg: {
      type: String,
      default: () => i18n.t("areYouSureToClearFormData"),
    },
    actions: {
      type: Array,
      default: () => [
        {
          btnClass: "btn btn-submit",
          title: i18n.t("clear"),
          fullBack: function () {
            console.log("fullBak-clear");
          },
        },
        {
          btnClass: "btn btn-cancel",
          title: i18n.t("no"),
          fullBack: function () {
            console.log("fullBak-no");
          },
        },
        {
          btnClass: "btn btn-leave",
          title: i18n.t("general.leave"),
          fullBack: function () {
            console.log("fullBak-leave");
          },
        },
      ],
    },
  },
  methods: {
    applyAction(fullBackFun) {
      fullBackFun();
      // this.$bvModal.hide(`CustomConfirmClearData-${this.dialogName}`);
      // this.$emit("response", data);
    },
  },
};
</script>

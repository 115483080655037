export default class LastFollowUpSessionData {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.followUpSessionToken = "";
    this.startSessionUserToken = "";
    this.endSessionUserToken = "";
    this.followUpSessionIsExpierStatus = false;
    this.sessionIsEndFromUserStatus = false;
    this.startSessionDateTime = "";
    this.startSessionDateTimeCustomized = "";
    this.startSessionDate = "";
    this.startSessionTime = "";
    this.endSessionDateTime = "";
    this.endSessionDateTimeCustomized = "";
    this.endSessionDate = "";
    this.endSessionTime = "";
    this.systemEndSessionDateTime = "";
    this.systemEndSessionDateTimeCustomized = "";
    this.systemEndSessionDate = "";
    this.systemEndSessionTime = "";
    this.sessionDurationCurrent = "";
  }
  fillData(data) {
    if (data) {
      this.followUpSessionToken = data.followUpSessionToken ?? "";
      this.startSessionUserToken = data.startSessionUserToken ?? "";
      this.endSessionUserToken = data.endSessionUserToken ?? "";
      this.followUpSessionIsExpierStatus =
        data.followUpSessionIsExpierStatus ?? false;
      this.sessionIsEndFromUserStatus =
        data.sessionIsEndFromUserStatus ?? false;
      this.startSessionDateTime = data.startSessionDateTime ?? "";
      this.startSessionDateTimeCustomized =
        data.startSessionDateTimeCustomized ?? "";
      this.startSessionDate = data.startSessionDate ?? "";
      this.startSessionTime = data.startSessionTime ?? "";
      this.endSessionDateTime = data.endSessionDateTime ?? "";
      this.endSessionDateTimeCustomized =
        data.endSessionDateTimeCustomized ?? "";
      this.endSessionDate = data.endSessionDate ?? "";
      this.endSessionTime = data.endSessionTime ?? "";
      this.systemEndSessionDateTime = data.systemEndSessionDateTime ?? "";
      this.systemEndSessionDateTimeCustomized =
        data.systemEndSessionDateTimeCustomized ?? "";
      this.systemEndSessionDate = data.systemEndSessionDate ?? "";
      this.systemEndSessionTime = data.systemEndSessionTime ?? "";
      this.sessionDurationCurrent = data.sessionDurationCurrent ?? "";
    } else {
      this.setInitialValue();
    }
  }
}

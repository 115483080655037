<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="FollowInterestAdd"
      size="xl"
      :headerText="$t('FollowInterests.add')"
      :headerIcon="followInterest.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="
        followInterest.setInitialValue(followInterest.userToken);
        closeFollowSessionStatus = false;
        openedStatus = true;
      "
      @closed="openedStatus = false"
    >
      <FollowInterestForm
        v-if="!isLoading"
        id="add"
        :followInterest="followInterest"
        v-on:submitForm="addFollowInterest()"
        v-on:closeBottomSheet="closeThisBottomSheetAndCustomConfirmClearData()"
        bottomSheetName="FollowInterestAdd"
        :submitName="$t('add')"
        :userToken="userToken"
        :closeFollowSessionStatus="closeFollowSessionStatus"
        :openedStatus="openedStatus"
      />
      <!-- :userSessionData="userSessionData" -->
    </CustomBottomSheet>
    <ConfirmClearData
      dialogName="FollowInterestAdd"
      v-on:response="response($event)"
    />
    <CustomConfirmClearData
      dialogName="FollowInterestAdd"
      :msg="$t('areYouSureToCloseSession')"
      :actions="getActionsCustomConfirmClearData()"
    />
  </div>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import ConfirmClearData from "./../../../../components/general/ConfirmClearData.vue";
import CustomConfirmClearData from "./../../../../components/general/CustomConfirmClearData.vue";
import FollowInterestForm from "./FollowInterestForm.vue";
import { STATUS } from "./../../../../utils/constants";
import apiFollowInterest from "./../../../../api/followUps/followInterests";
import generalMixin from "./../../../../utils/generalMixin";
// import FollowUpSession from "./../../../../models/followUps/followUpSessions/FollowUpSession";
// import apiFollowUpSession from "./../../../../api/followUps/followUpSessions";
// import { objectToFormData } from "./../../../../utils/functions";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    ConfirmClearData,
    CustomConfirmClearData,
    FollowInterestForm,
  },
  props: {
    followInterest: { type: Object },
    userToken: { type: String, default: "" },
  },
  data() {
    return {
      closeFollowSessionStatus: false,
      openedStatus: false,
    };
  },
  methods: {
    async addFollowInterest() {
      this.isLoading = true;
      try {
        const response = await apiFollowInterest.add(this.followInterest);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.show("ConfirmClearData-FollowInterestAdd");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    response(data) {
      this.$emit("refresh");
      switch (data) {
        case "yes":
          this.followInterest.setInitialValue();
          break;
        case "leave":
          this.followInterest.setInitialValue();
          this.closeBottomSheet("FollowInterestAdd");
          break;
      }
    },

    getActionsCustomConfirmClearData() {
      let list = [
        {
          btnClass: "btn btn-submit",
          title: this.$t("yes"),
          fullBack: this.closeFollowSession,
        },
        {
          btnClass: "btn btn-cancel",
          title: this.$t("no"),
          fullBack: this.closeThisBottomSheetAndCustomConfirmClearData,
        },
      ];
      return list;
    },
    async closeFollowSession() {
      this.closeFollowSessionStatus = true;
    },
    closeThisBottomSheet() {
      this.closeBottomSheet("FollowInterestAdd");
    },
    closeCustomConfirmClearData() {
      this.$bvModal.hide(`CustomConfirmClearData-FollowInterestAdd`);
    },
    closeThisBottomSheetAndCustomConfirmClearData() {
      this.closeCustomConfirmClearData();
      this.closeThisBottomSheet();
      this.$emit("refresh");
    },
  },
};
</script>

import { METHOD, request } from "./../../utils/request";
import { FollowUpSessions } from "./../api";

async function add(data) {
  return request(FollowUpSessions.add, METHOD.POST, data);
}

async function renewal(data) {
  return request(FollowUpSessions.renewal, METHOD.POST, data);
}

async function close(data) {
  return request(FollowUpSessions.close, METHOD.POST, data);
}

export default {
  add,
  renewal,
  close,
};
